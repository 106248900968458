import React from 'react'

import styled from 'styled-components'

export const Text = styled.p`
  font-size: ${(props) => props.$fontSize || '16'}px;
  font-weight: ${(props) => props.$fontWeight || 400};
  color: ${(props) => props.$color || '#0B3A89'};
  text-align: ${(props) => props.$textAlign || 'left'};
  line-height: ${(props) => props.$lineHeight || props.$fontSize || '16'}px;
`;

export const InlineText = Text.withComponent('span');
