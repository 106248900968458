import styled from 'styled-components';


export const Button = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  outline: none;
  width: ${(props) => props.$width || 'auto'};
  margin: ${(props) => props.$margin || '0'};
  height: ${(props) => props.$height};
`;
