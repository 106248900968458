import React from 'react'
import {
  Card,
  CardHeadRow,
  MainContainer,
  ThinHeadText,
  Text,
  CardHeading,
  Logo,
  CardBlock,
  Check,
  Line,
  LoadingContainer,
  MobileCheck,
  MobileLoadingContainer,
} from './style'

import TradeLogo from '../../images/LStock.svg'
import DocsLogo from '../../images/LDocs.svg'
import FlashLogo from '../../images/flash.svg'
import LendingLogo from '../../images/lend.svg'
import CheckMark from '../../images/Vector.svg'

import { TwinSpin } from 'react-cssfx-loading'

const Cards = () => {
  return (
    <MainContainer>
      <Line />
      <CardBlock>
        <Card>
          <CardHeadRow>
            <Logo src={TradeLogo} /> <ThinHeadText>Live</ThinHeadText>
            <MobileCheck>
              <img src={CheckMark} />
            </MobileCheck>
            {/* <MobileLoadingContainer>
              <TwinSpin color="#8098c2" />
            </MobileLoadingContainer> */}
          </CardHeadRow>
          <CardHeading>Swap Aggregator</CardHeading>
          <Text>
            Find the best token prices from across Near’s array of AMMs and
            Orderbook DEXs.
          </Text>
        </Card>
        <Check>
          <img src={CheckMark} />
        </Check>
        {/* <LoadingContainer>
          <TwinSpin color="#8098c2" />
        </LoadingContainer> */}
      </CardBlock>
      <CardBlock>
        <Card>
          <CardHeadRow>
            <Logo src={DocsLogo} /> <ThinHeadText>Live</ThinHeadText>
            <MobileCheck>
              <img src={CheckMark} />
            </MobileCheck>
            {/* <MobileLoadingContainer>
              <TwinSpin color="#8098c2" />
            </MobileLoadingContainer> */}
          </CardHeadRow>
          <CardHeading>Integration Docs</CardHeading>
          <Text>
            Seamlessly integrate our swap aggregator and future products into
            your Web 3 application through our docs.
          </Text>
        </Card>
        <Check>
          <img src={CheckMark} />
        </Check>
        {/* <LoadingContainer>
          <TwinSpin color="#8098c2" />
        </LoadingContainer> */}
      </CardBlock>
      <CardBlock>
        <Card>
          <CardHeadRow>
            <Logo src={FlashLogo} /> <ThinHeadText>Coming Soon</ThinHeadText>
            <MobileLoadingContainer>
              <TwinSpin color="#8098c2" />
            </MobileLoadingContainer>
          </CardHeadRow>
          <CardHeading>Concentrated Liquidity</CardHeading>
          <Text>
            Earn sustainable yields on our AMM through either auto-managed or
            manually-set concentrated liquidity positions.
          </Text>
        </Card>
        <LoadingContainer>
          <TwinSpin color="#8098c2" />
        </LoadingContainer>
      </CardBlock>
      <CardBlock>
        <Card>
          <CardHeadRow>
            <Logo src={LendingLogo} className="Lending" />
            <ThinHeadText>Coming Soon</ThinHeadText>
            <MobileLoadingContainer>
              <TwinSpin color="#8098c2" />
            </MobileLoadingContainer>
          </CardHeadRow>
          <CardHeading>Lending & Borrowing</CardHeading>
          <Text>
            Earn yield or borrow assets through our money market. Leveraged
            Farming for major Near AMMs to lever-up your positions.
          </Text>
        </Card>
        <LoadingContainer>
          <TwinSpin color="#8098c2" />
        </LoadingContainer>
      </CardBlock>
    </MainContainer>
  )
}

export default Cards
