import React, { useState, useEffect } from 'react'

import GlobalStyle from '../styles/globalStyles'
import NavBar from './NavBar/NavBar'
import Footer from './Footer/Footer'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga4';

const Layout = (props) => {
  return (
    <>
      <Helmet>
        <title>{`${'Perk | The Best Liquidity Aggregator Near you.'}`}</title>
        <meta
          name="description"
          content="The Best Liquidity Aggregator Near you."
        />
        <script>
          {ReactGA.initialize('G-GTFT6CRFP5')}
        </script>
      </Helmet>
      <GlobalStyle />
      <NavBar />
      <>{props.children}</>
      <Footer />
    </>
  )
}

export default Layout
