import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10em;
  padding-top: 2.2em;

  @media only screen and (max-width: 600px) {
    padding: 0 3em;
    padding-top: 4em;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8em;
  margin-left: 9em;

  @media only screen and (max-width: 800px) {
    display: none;
=  }
`

export const Links = styled.a`
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #8098c2;
  gap: 0.4em;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #6262f6;
    svg {
      path {
        stroke: #6262f6;
      }
    }
  }

  &:active {
    color: #0000f3;
    svg {
      path {
        stroke: #0000f3;
      }
    }
  }
`

export const Logo = styled.img``

export const LinksLogo = styled.img``

export const LaunchBtn = styled.a`
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.1em 4em;
  /* width: 15em; */
  background: #d0d1fa;
  box-shadow: 0px 16px 28px -10px rgba(0, 0, 226, 0.13);
  border-radius: 1.2em;
  font-size: 14px;
  color: #0b3a89;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 6px 18px -5px rgba(0, 0, 226, 0.13);
    background: #c9c9fb;
  }

  &:active {
    box-shadow: 0px 16px 28px -20px rgba(0, 0, 226, 0.13);
    background: #b6b6e7;
  }

  @media only screen and (max-width: 600px) {
    width: 12em;
    padding: 1em 1.8em;
  }
`
