import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
`;

export const FlexBlock = styled(Flex)`
  flex-direction: ${(props) => props.$direction || 'row'};
  justify-content: ${(props) =>
    props.$justifyContent || 'normal'};
  align-items: ${(props) => props.$alignItems || 'normal'};
  ${(props) => (props.$flex ? `flex: ${props.$flex};` : '')}
`;

export const FlexFull = styled(FlexBlock)`
    width: 100%;
`;