import * as React from 'react'
import Layout from '../components/Layout'
import Cards from '../fragments/Cards/Cards'
import Main from '../fragments/Main/Main'

const IndexPage = () => {
  return (
    <Layout>
      <Main />
      <Cards />
    </Layout>
  )
}

export default IndexPage
