import styled from 'styled-components'
import { Text as CommonText } from '../Typography'

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10em;
  padding-bottom: 1.2em;

  @media only screen and (max-width: 600px) {
    padding: 0 3em;
    margin-bottom: 2em;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3em;
`

export const Links = styled.a`
  font-size: 14px;
  font-weight: 400;
  color: #8098c2;
`

export const Logo = styled.img``

export const Text = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;

  color: rgba(10, 60, 147, 0.412);

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const OpenPrivacyPopupButton = styled(CommonText)`
  font-size: 12px;
  cursor: pointer;
  color: rgba(11, 58, 137, 0.35);
  margin-left: 16px;
`;