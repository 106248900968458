import styled, { keyframes } from 'styled-components';
import { Card } from '../Card';
import { Button } from '../Button';

const kf = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  animation: 0.1s ${kf} ease-out;
  background: #F4F6FB;
`;


export const ModalBody = styled(Card)`
  height: auto;
  max-width: 80em;
  max-height: 95vh;
  overflow: auto;
  overflow-x: hidden;
  width: ${(props) => props.$width || 'auto'};
`;

export const ModalContent = styled.div`
  padding: 0;
`;

export const ModalTitle = styled.h3`
  margin: 0;
  color: #0B3A89;
  padding-right: 16px;
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 32px 16px 16px 16px;
`;

export const CloseIcon = styled(Button)`
  cursor: pointer;
  font-weight: normal;
  background-color: #D4E2FA;
  width: 48px;
  height: 48px;
  border-radius: 12px;

  &:hover {
    background: #C3C5FA;
  }
`;
