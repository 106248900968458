import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 0 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;

  @media only screen and (max-width: 600px) {
    padding: 4em 3em;
    justify-content: flex-start;
    height: 100%;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3em;
  margin-bottom: 2.4em;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    gap: 3em;
    width: 100%;
  }
`

export const Link = styled.a`
  cursor: pointer;
  display: inline-block;

  &:hover {
    svg {
      path {
        fill: #6262f6;
      }
    }
  }

  &:active {
    svg {
      path {
        fill: #0000f3;
      }
    }
  }
`

export const Logo = styled.img`
  transition: all 4s ease-in-out;
`

export const HeadingContainer = styled.div`
  display: flex;
  margin-bottom: 1.8em;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

export const Heading = styled.div`
  font-family: 'Neue Plak';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 86px;
  text-align: center;
  text-transform: uppercase;
  color: #0b3a89;

  @media only screen and (max-width: 1500px) {
    font-size: 60px;
  }

  @media only screen and (max-width: 1412px) {
    font-size: 54px;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`
export const SpanHeading = styled(Heading)`
  font-family: 'Neue Plak Extended Extra';
  font-weight: 900;

  @media only screen and (max-width: 1500px) {
  }

  @media only screen and (max-width: 600px) {
    display: inline-block;
    font-size: 43px;
    line-height: 54px;
  }
`

export const MobileHeading = styled(Heading)`
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
    font-family: 'Neue Plak';
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    color: #0b3a89;
  }
`

export const Text = styled.div`
  font-family: 'Poppins';
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0b3a89;
  margin-bottom: 1.8em;
`

export const TradeBtn = styled.a`
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  width: 12.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000f3;
  box-shadow: 0px 16px 28px -20px rgba(0, 0, 226, 0.13);
  border-radius: 12px;
  padding: 0.8em 2em;
  color: #f4f6fb;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #0000f3;
    box-shadow: 0px 16px 16px -20px rgba(0, 0, 226, 0.13);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      #0000f3;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
