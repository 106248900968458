import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 0 10em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16em;
  position: relative;
  flex-wrap: wrap;

  @media only screen and (max-width: 1500px) {
    justify-content: center;
    gap: 2em;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 3em;
    align-items: center;
    justify-content: center;
    gap: 4em;
    margin-bottom: 8em;
    margin-top: 2em;
  }
`

export const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8em;
`

export const Check = styled.div`
  width: 3.2em;
  height: 3.2em;
  border-radius: 50%;
  background: #0000f3;
  box-shadow: 0px 8px 16px 2px rgba(0, 4, 109, 0.15);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1381px) {
    display: none;
  }
`

export const MobileCheck = styled.div`
  display: none;

  @media only screen and (max-width: 1381px) {
    display: flex;
    width: 3.2em;
    height: 3.2em;
    border-radius: 50%;
    background: #0000f3;
    box-shadow: 0px 8px 16px 2px rgba(0, 4, 109, 0.15);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const LoadingContainer = styled(Check)`
  background: #eaf1fc;
  box-shadow: 0px 8px 16px 2px rgba(0, 4, 109, 0.15);

  @media only screen and (max-width: 1381px) {
    display: none;
  }
`

export const MobileLoadingContainer = styled(LoadingContainer)`
  display: none;

  @media only screen and (max-width: 1381px) {
    display: flex;
    background: #eaf1fc;
    box-shadow: 0px 8px 16px 2px rgba(0, 4, 109, 0.15);
    z-index: 9;
  }
`

export const Card = styled.div`
  background: #eaf1fc;
  width: 31em;
  height: 31em;
  border-radius: 2.2em;
  padding: 2.2em;
  box-shadow: rgba(12, 12, 184, 0.13) 0px 7px 29px 0px;

  @media only screen and (min-width: 1700px) {
    width: 33em;
    height: 33em;
  }

  @media only screen and (max-width: 1500px) {
    width: 29em;
    height: 29em;
  }

  @media only screen and (max-width: 1428px) {
    width: 28em;
    height: 28em;
    padding: 2.4em;
  }

  @media only screen and (max-width: 1381px) {
    width: 34em;
    height: 33em;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 2.8em;
  }
`

export const CardHeadRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 7em;

  .Lending {
    width: 5.7em;

    @media only screen and (max-width: 1428px) {
      width: 4.7em;
    }
  }

  @media only screen and (max-width: 1500px) {
    margin-bottom: 5em;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 12em;
  }

  @media only screen and (max-width: 400px) {
    margin-bottom: 10em;
  }
`

export const ThinHeadText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 200%;
  text-align: right;
  color: #8098c2;

  @media only screen and (max-width: 1381px) {
    display: none;
  }
`

export const CardHeading = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #0b3a89;
  margin-bottom: 1em;

  @media only screen and (max-width: 1381px) {
    font-size: 2.2em;
  }

  @media only screen and (max-width: 600px) {
    font-size: 24px;
  }
`

export const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 171%;
  color: #0b3a89;

  @media only screen and (max-width: 1381px) {
    font-size: 1.6em;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

export const Logo = styled.img`
  width: 6em;

  @media only screen and (max-width: 1428px) {
    width: 5em;
  }
`

export const Line = styled.div`
  // background: linear-gradient(90deg, #0000f3 1.32%, #eaf1fc 66.09%); //TODO: Return after perk-app release
  background: #eaf1fc;
  width: 70%;
  height: 2px;
  top: 405px;
  left: 260px;
  z-index: -1;
  position: absolute;

  @media only screen and (min-width: 1778px) {
    width: 72%;
  }

  @media only screen and (min-width: 1700px) {
    top: 425px;
  }

  @media only screen and (max-width: 1600px) {
    width: 66.5%;
  }

  @media only screen and (max-width: 1500px) {
    top: 385px;
    width: 63%;
  }

  @media only screen and (max-width: 1428px) {
    top: 377px;
  }

  @media only screen and (max-width: 1381px) {
    display: none;
  }
`
