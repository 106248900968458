import React from 'react'
import ReactGA from 'react-ga4';

import {
  LaunchBtn,
  Links,
  LinksContainer,
  Logo,
  MainContainer,
  LinksLogo,
} from './styles'

import perkLogo from '../../images/Logo.svg'

const NavBar = () => {
  return (
    <MainContainer>
      <Logo src={perkLogo} />
      <LinksContainer>
        <Links
          target="_blank"
          rel="noreferrer noopener"
          href="https://app.perk.money"
          onClick={() => {
            ReactGA.event({
              action: 'trade_click',
              category: 'main',
            });
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.33333 3.33337H7.33333V9.33337H5.33333V11.3334H4V9.33337H2V3.33337H4V1.33337H5.33333V3.33337ZM3.33333 4.66671V8.00004H6V4.66671H3.33333ZM12 6.66671H14V12.6667H12V14.6667H10.6667V12.6667H8.66667V6.66671H10.6667V4.66671H12V6.66671ZM10 8.00004V11.3334H12.6667V8.00004H10Z"
              fill="#8098C2"
            />
          </svg>
          Trade
        </Links>
        <Links
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.perk.money/"
          onClick={() => {
            ReactGA.event({
              action: 'docs_click',
              category: 'main',
            });
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.66667 4.66667H11.3333M4.66667 8H11.3333M4.66667 11.3333H8.66667M12.6667 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V3.33333C14 2.97971 13.8595 2.64057 13.6095 2.39052C13.3594 2.14048 13.0203 2 12.6667 2Z"
              stroke="#8098C2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Docs
        </Links>
      </LinksContainer>
      <LaunchBtn
        target="_blank"
        rel="noreferrer noopener"
        href="https://app.perk.money"
        onClick={() => {
          ReactGA.event({
            action: 'launch_app_click',
            category: 'main',
          });
        }}
      >
        Launch App
      </LaunchBtn>
    </MainContainer>
  )
}

export default NavBar
