import React from 'react'

import styled from 'styled-components';
import { Text } from '../../components/Typography/index';

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 32px 16px 16px 16px;
`;

const Title = styled((props) => (
  <Text $fontWeight={700} $fontSize="20" {...props} />
))`
  margin-top: 20px;
  margin-bottom: 12px;
`;

const SubTitle = styled((props) => <Title {...props} />)`
  font-size: 16px;
`;

const StyledText = styled((props) => <Text $fontSize="12" {...props} />)`
  margin-top: 20px;
  margin-bottom: 12px;
  letter-spacing: 0.2px;
  line-height: 150%;
`;

const StyledModalTitleContainer = styled(ModalTitleContainer)`
  margin-left: 0;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;

  @media (min-width: 720px) {
    max-width: 720px;
  }

  padding: 16px;

  ul {
    padding-inline-start: 40px;
  }
`;

export { Title, SubTitle, StyledText, StyledModalTitleContainer, ModalContentContainer };