// globalStyles.js
import { createGlobalStyle } from 'styled-components'
import '../styles/fonts.css'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
		box-sizing: border-box;
		margin: 0;
    	padding: 0;
	}

	html {
	  font-size: 10px;
	  position: relative;
	  color: black;
	  box-sizing: border-box;
	  -webkit-font-smoothing: antialiased;
	 

	}

	body {
		margin: 0;
		padding: 0;
		line-height: 1.5;
		font-family: Poppins, sans-serif;
		overflow-x: hidden;
		overflow-y: scroll;
		background:#F4F6FB;

    	background-image: radial-gradient(#8098C2 3.25%, transparent 4.25%),
     	radial-gradient(#F4F6FB 3%, transparent 3%);
    	background-position: 0 0, 50px 50px;
    	background-size: 17px 17px;
    	/* height: 200px; */
    	width: 100%;
	}

  body.modal-open {
    height: 100vh;
    overflow-y: hidden;
  }

  body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  body::-webkit-scrollbar-thumb {
    background: transparent;
  }
  body::-webkit-scrollbar-track {
    background: transparent;
  }

`

export default GlobalStyle
